<template>
  <div class="municipality">
    <v-card class="table-dashboard">
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="usersConference"
        :footer-props="{
          'items-per-page-text': 'Usuários por Página',
        }"
        :page="page"
        :pageCount="numberOfPages"
        :options.sync="options"
        :sort-by.sync="sortBy"
        :server-items-length="totalUsers"
        :loading="loading"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-spacer></v-spacer>
            <v-dialog>
              <template v-slot:activator="{ off, attrs }">
                <v-btn
                  v-show="enableButton && ($store.state.profile_id == 1 || $store.state.profile_id == 2)"
                  color="green"
                  @click="redirectRouter()"
                  dark
                  class="mb-2 mr-5"
                  v-bind="attrs"
                >
                  + Nova Conferência
                </v-btn>
              </template>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:item.actions="{ item }">
          <div class="table-center">
            <v-tooltip bottom color="success">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  small
                  class="mr-2"
                  v-bind="attrs"
                  v-on="on"
                  @click="routerProps(item)"
                >
                  mdi-pencil
                </v-icon>
              </template>
              <span>Editar</span>
            </v-tooltip>
            <v-tooltip bottom color="success">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  small
                  class="mr-2"
                  v-bind="attrs"
                  v-on="on"
                  @click="report(item)"
                >
                  mdi-format-list-bulleted-type
                </v-icon>
              </template>
              <span>Relatórios</span>
            </v-tooltip>

            <v-tooltip bottom color="success">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  small
                  class="mr-2"
                  v-bind="attrs"
                  v-on="on"
                  @click="routerLaunch(item)"
                >
                  mdi-tray-arrow-down
                </v-icon>
              </template>
              <span>Lançamento</span>
            </v-tooltip>
            <v-tooltip
              class="tooltip-active"
              bottom
              :color="!item.deleted_at ? 'success' : 'error'"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-chip
                  class="status-conference"
                  :color="!item.deleted_at ? 'success' : 'error'"
                  dark
                  v-bind="attrs"
                  v-on="on"
                >
                </v-chip>
              </template>
              <span>{{ !item.deleted_at ? "Ativado" : "Desativado" }}</span>
            </v-tooltip>
          </div>
        </template>
      </v-data-table>
    </v-card>
    <v-snackbar v-model="snackbar">
      {{ message2 }}
      <template v-slot:action="{ attrs }">
        <v-btn color="green" text v-bind="attrs" @click="snackbar = false">
          Fechar
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
import axios from "@/axios";
export default {
  data: () => ({
    viewButtonNewConference: false,
    dialog: false,
    loading: true,
    message2: [],
    snackbar: false,
    page: 1,
    totalUsers: 0,
    numberOfPages: 0,
    usersConference: [],
    search: "",

    options: {},
    sortBy: "title",
    isPermission: null,

    dialogDelete: false,
    headers: [
      { text: "AÇÕES", value: "actions", sortable: false },
      {
        text: "TÍTULO",
        align: "start",
        sortable: false,
        value: "title",
      },
      { text: "DATA DE INÍCIO", value: "initial_state_phase_date" },
      { text: "DATA DE ENCERRAMENTO", value: "final_state_phase_date" },
    ],
    enableButton: false,
    desserts: [],
    editedIndex: -1,
    editedItem: {
      name: "",
      calories: 0,
      fat: 0,
      carbs: 0,
      protein: 0,
    },
    defaultItem: {
      name: "",
      calories: 0,
      fat: 0,
      carbs: 0,
      protein: 0,
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Cadastrar" : "Editar Item";
    },
  },

  watch: {
    viewButtonNewConference(val) {
      this.enableButton = val;      
    },
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    sortBy: {
      handler() {
        console.log(this.sortBy);
      },
    },
    options: {
      handler() {
        this.confDataTable();
      },
    },
  },

  methods: {
    report(item) {
      this.$router.push({
        path: "/dashboard/conference/report",
        query: { id: item.id },
      });
    },

    confDataTable() {
      this.loading = true;
      const { page } = this.options;
      let pageNumber = page;
      let data = new Object();
      data.per_page = this.options.itemsPerPage;

      axios
        .get(`/conference/search`, {
          params: {
            page: pageNumber,
            per_page: data.per_page,
          },
        })
        .then((res) => {
          this.loading = false;
          this.usersConference = res.data.data;
          this.totalUsers = res.data.total;
          this.numberOfPages = res.data.last_page;
          this.viewButtonNewConference = !this.verifyIfExistActiveConference(
            this.usersConference
          );
        });
    },

    verifyIfExistActiveConference(conferenceArray) {
      if (conferenceArray.filter((x) => x.deleted_at == null).length != 0) {
        return true;
      }
      return false;
    },

    deleteItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    redirectRouter() {
      this.$router.push({ path: '/dashboard/conference/new' });
    },

    routerLaunch(item) {
      setTimeout(() => {
        if (
          Number(this.$store.state.profile_id) == 1 ||
          Number(this.$store.state.profile_id) == 2 ||
          Number(this.$store.state.profile_id) == 21 ||
          Number(this.$store.state.profile_id) == 41
        ) {
          this.$router.push({
            path: "/dashboard/conference/general",
            query: { id: item.id },
          });
        } else {
          this.message2 =
            "Acesso negado: você não possui permissões necessárias.";
          this.snackbar = true;
        }
      }, 1000);
    },

    routerProps(item) {
      setTimeout(() => {
        if (
          Number(this.$store.state.profile_id) == 1 ||
          Number(this.$store.state.profile_id) == 2
        ) {
          this.$router.push({
            path: "/dashboard/conference/edit",
            query: { id: item.id },
          });
        } else {
          this.message2 =
            "Acesso negado: você não possui permissões necessárias.";
          this.snackbar = true;
        }
      }, 1000);
    },

    deleteItemConfirm() {
      this.desserts.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.desserts[this.editedIndex], this.editedItem);
      } else {
        this.desserts.push(this.editedItem);
      }
      this.close();
    },
  },

  mounted() {
    this.confDataTable();
  },
};
</script>

<style>
.status-conference {
  height: 15px !important;
  border-radius: 50%;
}

.v-chip {
  padding: 0 3px !important;
}

.table-center {
  display: flex;
  align-items: center;
}
</style>
