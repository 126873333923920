<template>
  <div class="main-municipality">
    <tableListConfe />
  </div>
</template>

<script>
import tableListConfe from '../table-conference/table-list-confe.vue'
export default {
  components: {
    tableListConfe
  }
};
</script>

<style>
.v-sheet--offset {
  top: -24px;
  position: relative;
}

.responsibility {
  margin-bottom: 20px;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.responsibility span span {
  font-weight: 500;
}

.responsibility p {
  margin: 0;
}

.responsibility p span {
  font-weight: 500;
}

.table-button-modal {
  display: flex;
  justify-content: start;
  padding-left: 8px;
  margin-top: 5px;
  align-items: center;
  flex-direction: row;
}

.table-button-modal>button {
  margin-right: 10px;
  padding: 0px 7px;
  color: white;
  cursor: pointer;
  background-color: rgb(104, 101, 101);
  border-radius: 50%;
}

.main-municipality {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.table-top-margin {
  margin-top: 120px !important;
}

.title-span {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: row;
}

.title-span span {
  width: auto;
  margin-right: 15px;
}

.title-span hr {
  width: 100%;
  height: 2px;
}

.municipality {
  width: 100%;
  margin-top: 15px;
}

.municipality span {
  font-weight: 500;
}

.table-dashboard {
  width: 100%;
}

.card-chart {
  border: 1px solid salmon !important;
  border-radius: 8px !important;
}
</style>
